@-webkit-keyframes spin {
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@keyframes spin {
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@-webkit-keyframes ping {

    75%,
    to {
        -webkit-transform: scale(2);
        transform: scale(2);
        opacity: 0
    }
}

@keyframes ping {

    75%,
    to {
        -webkit-transform: scale(2);
        transform: scale(2);
        opacity: 0
    }
}

@-webkit-keyframes pulse {
    50% {
        opacity: .5
    }
}

@keyframes pulse {
    50% {
        opacity: .5
    }
}

@-webkit-keyframes bounce {

    0%,
    to {
        -webkit-transform: translateY(-25%);
        transform: translateY(-25%);
        -webkit-animation-timing-function: cubic-bezier(.8, 0, 1, 1);
        animation-timing-function: cubic-bezier(.8, 0, 1, 1)
    }

    50% {
        -webkit-transform: none;
        transform: none;
        -webkit-animation-timing-function: cubic-bezier(0, 0, .2, 1);
        animation-timing-function: cubic-bezier(0, 0, .2, 1)
    }
}

@keyframes bounce {

    0%,
    to {
        -webkit-transform: translateY(-25%);
        transform: translateY(-25%);
        -webkit-animation-timing-function: cubic-bezier(.8, 0, 1, 1);
        animation-timing-function: cubic-bezier(.8, 0, 1, 1)
    }

    50% {
        -webkit-transform: none;
        transform: none;
        -webkit-animation-timing-function: cubic-bezier(0, 0, .2, 1);
        animation-timing-function: cubic-bezier(0, 0, .2, 1)
    }
}

@-webkit-keyframes fadein {
    0% {
        visibility: hidden;
        opacity: 0
    }

    to {
        visibility: visible;
        opacity: 1
    }
}

@keyframes fadein {
    0% {
        visibility: hidden;
        opacity: 0
    }

    to {
        visibility: visible;
        opacity: 1
    }
}

@-webkit-keyframes fadeout {
    0% {
        visibility: visible;
        opacity: 1
    }

    to {
        visibility: hidden;
        opacity: 0
    }
}

@keyframes fadeout {
    0% {
        visibility: visible;
        opacity: 1
    }

    to {
        visibility: hidden;
        opacity: 0
    }
}

@-webkit-keyframes fadeinup {
    0% {
        visibility: hidden;
        opacity: 0;
        -webkit-transform: translateY(5px);
        transform: translateY(5px)
    }

    to {
        visibility: visible;
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }
}

@keyframes fadeinup {
    0% {
        visibility: hidden;
        opacity: 0;
        -webkit-transform: translateY(5px);
        transform: translateY(5px)
    }

    to {
        visibility: visible;
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }
}

@-webkit-keyframes fadeindown {
    0% {
        visibility: hidden;
        opacity: 0;
        -webkit-transform: translateY(-5px);
        transform: translateY(-5px)
    }

    to {
        visibility: visible;
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }
}

@keyframes fadeindown {
    0% {
        visibility: hidden;
        opacity: 0;
        -webkit-transform: translateY(-5px);
        transform: translateY(-5px)
    }

    to {
        visibility: visible;
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }
}

@-webkit-keyframes fadeoutdown {
    0% {
        visibility: visible;
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }

    to {
        visibility: hidden;
        opacity: 0;
        -webkit-transform: translateY(5px);
        transform: translateY(5px)
    }
}

@keyframes fadeoutdown {
    0% {
        visibility: visible;
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }

    to {
        visibility: hidden;
        opacity: 0;
        -webkit-transform: translateY(5px);
        transform: translateY(5px)
    }
}

@-webkit-keyframes fadeoutup {
    0% {
        visibility: visible;
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }

    to {
        visibility: hidden;
        opacity: 0;
        -webkit-transform: translateY(-5px);
        transform: translateY(-5px)
    }
}

@keyframes fadeoutup {
    0% {
        visibility: visible;
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }

    to {
        visibility: hidden;
        opacity: 0;
        -webkit-transform: translateY(-5px);
        transform: translateY(-5px)
    }
}

@-webkit-keyframes float-5 {

    0%,
    to {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }

    50% {
        -webkit-transform: translateY(-5px);
        transform: translateY(-5px)
    }
}

@keyframes float-5 {

    0%,
    to {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }

    50% {
        -webkit-transform: translateY(-5px);
        transform: translateY(-5px)
    }
}

@-webkit-keyframes float-10 {

    0%,
    to {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }

    50% {
        -webkit-transform: translateY(-10px);
        transform: translateY(-10px)
    }
}

@keyframes float-10 {

    0%,
    to {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }

    50% {
        -webkit-transform: translateY(-10px);
        transform: translateY(-10px)
    }
}

@-webkit-keyframes float-15 {

    0%,
    to {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }

    50% {
        -webkit-transform: translateY(-15px);
        transform: translateY(-15px)
    }
}

@keyframes float-15 {

    0%,
    to {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }

    50% {
        -webkit-transform: translateY(-15px);
        transform: translateY(-15px)
    }
}

@-webkit-keyframes fadeinleft {
    0% {
        visibility: hidden;
        opacity: 0;
        -webkit-transform: translateX(5px);
        transform: translateX(5px)
    }

    to {
        visibility: visible;
        opacity: 1;
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }
}

@keyframes fadeinleft {
    0% {
        visibility: hidden;
        opacity: 0;
        -webkit-transform: translateX(5px);
        transform: translateX(5px)
    }

    to {
        visibility: visible;
        opacity: 1;
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }
}

@-webkit-keyframes fadeoutleft {
    0% {
        visibility: visible;
        opacity: 1;
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }

    to {
        visibility: hidden;
        opacity: 0;
        -webkit-transform: translateX(-5px);
        transform: translateX(-5px)
    }
}

@keyframes fadeoutleft {
    0% {
        visibility: visible;
        opacity: 1;
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }

    to {
        visibility: hidden;
        opacity: 0;
        -webkit-transform: translateX(-5px);
        transform: translateX(-5px)
    }
}

@-webkit-keyframes fadeinright {
    0% {
        visibility: hidden;
        opacity: 0;
        -webkit-transform: translateX(-5px);
        transform: translateX(-5px)
    }

    to {
        visibility: visible;
        opacity: 1;
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }
}

@keyframes fadeinright {
    0% {
        visibility: hidden;
        opacity: 0;
        -webkit-transform: translateX(-5px);
        transform: translateX(-5px)
    }

    to {
        visibility: visible;
        opacity: 1;
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }
}

@-webkit-keyframes fadeoutright {
    0% {
        visibility: visible;
        opacity: 1;
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }

    to {
        visibility: hidden;
        opacity: 0;
        -webkit-transform: translateX(5px);
        transform: translateX(5px)
    }
}

@keyframes fadeoutright {
    0% {
        visibility: visible;
        opacity: 1;
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }

    to {
        visibility: hidden;
        opacity: 0;
        -webkit-transform: translateX(5px);
        transform: translateX(5px)
    }
}

@-webkit-keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
    }

    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
    }

    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

*,
::after,
::before {
    box-sizing: border-box;
    border-width: 0;
    border-style: solid;
    border-color: currentColor;
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
    --tw-shadow: 0 0 #0000;
    --tw-ring-inset: var(--tw-empty,
            /*!*/
            /*!*/
        );
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgba(59, 130, 246, 0.5);
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000
}

html {
    -moz-tab-size: 4;
    -o-tab-size: 4;
    tab-size: 4;
    -webkit-text-size-adjust: 100%;
    font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    line-height: 1.5
}

body {
    font-family: system-ui, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';
    line-height: inherit;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400
}

hr {
    height: 0;
    color: inherit
}

abbr[title] {
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted
}

b,
strong {
    font-weight: bolder
}

code,
kbd,
pre,
samp {
    font-size: 1em
}

small {
    font-size: 65%
}

sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline
}

sub {
    bottom: -.25em
}

sup {
    top: -.5em
}

table {
    text-indent: 0;
    border-color: inherit;
    border-collapse: collapse
}

button,
input,
optgroup,
select,
textarea {
    font-family: inherit;
    font-size: 100%;
    margin: 0
}

button,
select {
    text-transform: none
}

[type=button],
button {
    -webkit-appearance: button
}

legend {
    padding: 0
}

progress {
    vertical-align: baseline
}

a,
a:hover {
    text-decoration: none;
}

summary {
    display: list-item
}

blockquote,
body,
dd,
dl,
figure,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
p,
pre {
    margin: 0
}

button {
    background-color: transparent;
    background-image: none;
    cursor: pointer
}

fieldset,
ol,
ul {
    margin: 0;
    padding: 0
}

ol,
ul {
    list-style: none
}

hr {
    border-top-width: 1px
}

img {
    border-style: solid
}

textarea {
    resize: vertical
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
    opacity: 1;
    color: #9ca3af
}

input::-moz-placeholder,
textarea::-moz-placeholder {
    opacity: 1;
    color: #9ca3af
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
    opacity: 1;
    color: #9ca3af
}

input::placeholder,
textarea::placeholder {
    opacity: 1;
    color: #9ca3af
}

h1,
h2,
h3,
h4 {
    font-size: inherit;
    font-family: 'Circular';
    font-style: normal;
    font-weight: 400
}

h5,
h6 {
    font-size: inherit;
    font-weight: inherit
}

a {
    color: inherit;
    text-decoration: inherit
}

button,
input,
optgroup,
select,
textarea {
    padding: 0;
    line-height: inherit;
    color: inherit
}

code,
kbd,
pre,
samp {
    font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace
}

audio,
canvas,
embed,
iframe,
img,
object,
svg,
video {
    display: block;
    vertical-align: middle
}

img,
video {
    max-width: 100%;
    height: auto
}

.invisible {
    visibility: hidden
}

.fixed {
    position: fixed
}

.absolute {
    position: absolute
}

.relative {
    position: relative
}

.sticky {
    position: -webkit-sticky;
    position: sticky
}

.top-0 {
    top: 0
}

.top-72px {
    top: 72px
}

.top-120px {
    top: 120px
}

.top-180px {
    top: 180px
}

.top-224px {
    top: 224px
}

.top-244px {
    top: 244px
}

.top-5pc {
    top: 5%
}

.top-10pc {
    top: 10%
}

.top-20pc {
    top: 20%
}

.top-25pc {
    top: 25%
}

.top-45pc {
    top: 45%
}

.top-70pc {
    top: 70%
}

.-top-256px {
    top: -256px
}

.-top-15pc {
    top: -15%
}

.-top-25pc {
    top: -25%
}

.-top-30pc {
    top: -30%
}

.-top-50pc {
    top: -50%
}

.top-1\/2 {
    top: 50%
}

.top-2px {
    top: 2px
}

.top-3pc {
    top: 3%
}

.right-0 {
    right: 0
}

.right-16px {
    right: 16px
}

.right-24px {
    right: 24px
}

.right-32px {
    right: 32px
}

.right-60px {
    right: 60px
}

.right-15pc {
    right: 15%
}

.right-20pc {
    right: 20%
}

.right-40pc {
    right: 40%
}

.right-85pc {
    right: 85%
}

.right-90pc {
    right: 90%
}

.right-95pc {
    right: 95%
}

.-right-5pc {
    right: -5%
}

.-right-10pc {
    right: -10%
}

.-right-80pc {
    right: -80%
}

.-right-100pc {
    right: -100%
}

.bottom-0 {
    bottom: 0
}

.bottom-32px {
    bottom: 32px
}

.bottom-224px {
    bottom: 224px
}

.bottom-244px {
    bottom: 244px
}

.bottom-10pc {
    bottom: 10%
}

.bottom-15pc {
    bottom: 15%
}

.bottom-20pc {
    bottom: 20%
}

.bottom-25pc {
    bottom: 25%
}

.bottom-40pc {
    bottom: 40%
}

.bottom-75pc {
    bottom: 75%
}

.-bottom-15pc {
    bottom: -15%
}

.-bottom-20pc {
    bottom: -20%
}

.-bottom-30pc {
    bottom: -30%
}

.-bottom-100pc {
    bottom: -100%
}

.bottom-1\/2 {
    bottom: 50%
}

.left-0 {
    left: 0
}

.left-16px {
    left: 16px
}

.left-24px {
    left: 24px
}

.left-32px {
    left: 32px
}

.left-25pc {
    left: 25%
}

.left-30pc {
    left: 30%
}

.left-90pc {
    left: 90%
}

.left-95pc {
    left: 95%
}

.left-125pc {
    left: 125%
}

.-left-5pc {
    left: -5%
}

.-left-20pc {
    left: -20%
}

.-left-40pc {
    left: -40%
}

.-left-80pc {
    left: -80%
}

.-left-100pc {
    left: -100%
}

.left-1\/2 {
    left: 50%
}

.left-full {
    left: 100%
}

.left-2px {
    left: 2px
}

.-left-480px {
    left: -480px
}

.z-0 {
    z-index: 0
}

.z-10 {
    z-index: 10
}

.z-20 {
    z-index: 20
}

.z-30 {
    z-index: 30
}

.z-50 {
    z-index: 50
}

.mx-2 {
    margin-left: .5rem;
    margin-right: .5rem
}

.mx-auto {
    margin-left: auto;
    margin-right: auto
}

.my-32px {
    margin-top: 32px;
    margin-bottom: 32px
}

.mt-8 {
    margin-top: 2rem
}

.mt-auto {
    margin-top: auto
}

.mt-4px {
    margin-top: 4px
}

.mt-8px {
    margin-top: 8px
}

.mt-12px {
    margin-top: 12px
}

.mt-16px {
    margin-top: 16px
}

.mt-20px {
    margin-top: 20px
}

.mt-24px {
    margin-top: 24px
}

.mt-28px {
    margin-top: 28px
}

.gutenberg.docs h3,
.mt-32px {
    margin-top: 32px
}

.mt-44px {
    margin-top: 44px
}

.mt-48px {
    margin-top: 48px
}

.mt-52px {
    margin-top: 52px
}

.mt-56px {
    margin-top: 56px
}

.mt-60px {
    margin-top: 60px
}

.mt-64px {
    margin-top: 64px
}

.mt-80px {
    margin-top: 80px
}

.mt-136px {
    margin-top: 136px
}

.mt-176px {
    margin-top: 176px
}

.-mt-15pc {
    margin-top: -15%
}

.mr-8px {
    margin-right: 8px
}

.mb-4px {
    margin-bottom: 4px
}

.mb-24px {
    margin-bottom: 24px
}

.mb-32px {
    margin-bottom: 32px
}

.mb-56px {
    margin-bottom: 56px
}

.ml-auto {
    margin-left: auto
}

.ml-8px {
    margin-left: 8px
}

.ml-16px {
    margin-left: 16px
}

.-ml-15pc {
    margin-left: -15%
}

.-ml-25pc {
    margin-left: -25%
}

.block {
    display: block
}

.inline-block {
    display: inline-block
}

.inline {
    display: inline
}

.flex {
    display: -webkit-flex;
    display: flex
}

.table {
    display: table
}

.grid {
    display: grid
}

.hidden {
    display: none
}

.h-24px {
    height: 24px
}

.h-36px {
    height: 36px
}

.h-40px {
    height: 40px
}

.h-48px {
    height: 48px
}

.h-56px {
    height: 56px
}

.h-full {
    height: 100%
}

.max-h-full {
    max-height: 100%
}

.max-h-screen {
    max-height: 100vh
}

.w-24px {
    width: 24px
}

.w-36px {
    width: 36px
}

.w-40px {
    width: 40px
}

.w-48px {
    width: 48px
}

.w-232px {
    width: 232px
}

.w-236px {
    width: 236px
}

.w-240px {
    width: 240px
}

.w-35pc {
    width: 35%
}

.w-40pc {
    width: 40%
}

.w-50pc {
    width: 50%
}

.w-60pc {
    width: 60%
}

.w-80pc {
    width: 80%
}

.w-85pc {
    width: 85%
}

.w-95pc {
    width: 95%
}

.w-100pc {
    width: 100%
}

.w-110pc {
    width: 110%
}

.w-120pc {
    width: 120%
}

.w-1\/3 {
    width: 33.333333%
}

.w-full {
    width: 100%
}

.w-screen {
    width: 98.7vw
}

.w-140pc {
    width: 140%
}

.w-150pc {
    width: 150%
}

.w-160pc {
    width: 160%
}

.w-170pc {
    width: 170%
}

.max-w-none {
    max-width: none
}

.max-w-md {
    max-width: 28rem
}

.max-w-screen-sm {
    max-width: 640px
}

.max-w-screen-md {
    max-width: 768px
}

.max-w-screen-lg {
    max-width: 1024px
}

.max-w-screen-xl {
    max-width: 1280px
}

.max-w-screen-2xl {
    max-width: 1536px
}

.max-w-140pc {
    max-width: 140%
}

.max-w-160pc {
    max-width: 160%
}

.max-w-236px {
    max-width: 236px
}

.max-w-300px {
    max-width: 300px
}

.max-w-340px {
    max-width: 340px
}

.max-w-400px {
    max-width: 400px
}

.max-w-448px {
    max-width: 448px
}

.max-w-900px {
    max-width: 900px
}

.flex-shrink-0 {
    -webkit-flex-shrink: 0;
    flex-shrink: 0
}

.flex-grow {
    -webkit-flex-grow: 1;
    flex-grow: 1
}

.transform {
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    -webkit-transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.-translate-x-1\/2 {
    --tw-translate-x: -50%
}

.-rotate-45 {
    --tw-rotate: -45deg
}

.scale-50 {
    --tw-scale-x: .5;
    --tw-scale-y: .5
}

.cursor-pointer {
    cursor: pointer
}

.grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr))
}

.flex-col {
    -webkit-flex-direction: column;
    flex-direction: column
}

.items-start {
    -webkit-align-items: flex-start;
    align-items: flex-start
}

.items-end {
    -webkit-align-items: flex-end;
    align-items: flex-end
}

.items-center {
    -webkit-align-items: center;
    align-items: center
}

.justify-center {
    -webkit-justify-content: center;
    justify-content: center
}

.gap-32px {
    gap: 32px
}

.gap-y-16px {
    row-gap: 16px
}

.overflow-hidden {
    overflow: hidden
}

.overflow-visible {
    overflow: visible
}

.overflow-scroll {
    overflow: scroll
}

.overflow-x-scroll {
    overflow-x: scroll
}

.whitespace-nowrap {
    white-space: nowrap
}

.rounded-full {
    border-radius: 9999px
}

.rounded-8px {
    border-radius: 8px
}

.rounded-16px {
    border-radius: 16px
}

.rounded-t-10px {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px
}

.rounded-b-8px {
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px
}

.border-t {
    border-top-width: 1px
}

.border-r {
    border-right-width: 1px
}

.border-b {
    border-bottom-width: 1px
}

.border-wtf-gainsboro {
    --tw-border-opacity: 1;
    border-color: rgba(234, 234, 235, var(--tw-border-opacity))
}

.bg-white {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity))
}

.bg-wtf-pigment-blue {
    --tw-bg-opacity: 1;
    background-color: rgba(53, 56, 157, var(--tw-bg-opacity))
}

.bg-wtf-blue-jeans,
.bg-wtf-cultured {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 172, 255, var(--tw-bg-opacity))
}

.bg-wtf-cultured {
    background-color: rgba(247, 247, 248, var(--tw-bg-opacity))
}

.bg-wtf-majorelle-blue,
.bg-wtf-mango {
    --tw-bg-opacity: 1;
    background-color: rgba(112, 71, 235, var(--tw-bg-opacity))
}

.bg-wtf-mango {
    background-color: rgba(255, 194, 51, var(--tw-bg-opacity))
}

.hover\:bg-wtf-blue-jeans-hover:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(41, 179, 255, var(--tw-bg-opacity))
}

.gutenberg li code,
.gutenberg p code,
.hover\:bg-wtf-cultured:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(247, 247, 248, var(--tw-bg-opacity));
    color: rgb(37,196,55);
}

.gutenberg .wp-block-button a:hover,
.hover\:bg-wtf-majorelle-blue-hover:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 196, 55, var(--tw-bg-opacity))
}

.hover\:bg-wtf-mango-hover:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 200, 76, var(--tw-bg-opacity))
}

.hover\:bg-wtf-gainsboro:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(234, 234, 235, var(--tw-bg-opacity))
}

.bg-opacity-50 {
    --tw-bg-opacity: 0.5
}

.bg-opacity-90 {
    --tw-bg-opacity: 0.9
}

.bg-opacity-95 {
    --tw-bg-opacity: 0.95
}

.bg-gradient-to-t {
    background-image: linear-gradient(to top, var(--tw-gradient-stops))
}

.from-white {
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0))
}

.stroke-current {
    stroke: currentColor
}

.px-16px {
    padding-left: 16px;
    padding-right: 16px
}

.px-24px {
    padding-left: 24px;
    padding-right: 24px
}

.px-32px {
    padding-left: 32px;
    padding-right: 32px
}

.px-48px {
    padding-left: 48px;
    padding-right: 48px
}

.py-24px {
    padding-top: 24px;
    padding-bottom: 24px
}

.py-32px {
    padding-top: 32px;
    padding-bottom: 32px
}

.py-48px {
    padding-top: 48px;
    padding-bottom: 48px
}

.py-56px {
    padding-top: 56px;
    padding-bottom: 56px
}

.py-60px {
    padding-top: 60px;
    padding-bottom: 60px
}

.py-72px {
    padding-top: 72px;
    padding-bottom: 72px
}

.py-124px {
    padding-top: 124px;
    padding-bottom: 124px
}

.pt-32px {
    padding-top: 32px
}

.pt-56px {
    padding-top: 56px
}

.pt-60px {
    padding-top: 60px
}

.pt-88px {
    padding-top: 88px
}

.pt-124px {
    padding-top: 124px
}

.pt-20pc {
    padding-top: 20%
}

.pt-50pc {
    padding-top: 50%
}

.pt-100pc {
    padding-top: 100%
}

.pr-20px {
    padding-right: 20px
}

.pr-48px {
    padding-right: 48px
}

.pr-10pc {
    padding-right: 10%
}

.pb-10 {
    padding-bottom: 2.5rem
}

.pb-32px {
    padding-bottom: 32px
}

.pb-60px {
    padding-bottom: 60px
}

.pb-104px {
    padding-bottom: 104px
}

.pb-116px {
    padding-bottom: 116px
}

.gutenberg li,
.pl-16px {
    padding-left: 16px
}

.pl-10pc {
    padding-left: 10%
}

.text-center {
    text-align: center
}

.text-sm {
    font-size: .875rem;
    line-height: 1.25rem
}

.gutenberg code,
.text-12px {
    font-size: 12px;
    line-height: 14px
}

.text-14px {
    font-size: 14px;
    line-height: 24px
}

.gutenberg.docs ol,
.gutenberg.docs p,
.gutenberg.docs ul,
.text-16px {
    font-size: 16px;
    line-height: 28px
}

.text-18px {
    font-size: 18px;
    line-height: 32px
}

.text-22px,
.text-24px {
    font-size: 22px;
    line-height: 32px;
    letter-spacing: -.01em
}

.text-24px {
    font-size: 24px
}

.text-30px,
.text-36px {
    font-size: 30px;
    line-height: 40px;
    letter-spacing: -.025em
}

.text-36px {
    font-size: 36px;
    line-height: 43px
}

.gutenberg h1,
.text-48px {
    font-size: 48px;
    line-height: 56px;
    letter-spacing: -.025em
}

.font-500 {
    font-weight: 500
}

.font-600,
.gutenberg strong {
    font-weight: 600
}

.font-medium {
    font-weight: 500
}

.leading-tight {
    line-height: 1.25
}

.text-white {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity))
}

.text-wtf-blue-jeans,
.text-wtf-gunmetal {
    --tw-text-opacity: 1;
    color: rgba(0, 172, 255, var(--tw-text-opacity))
}

.text-wtf-gunmetal {
    color: rgba(37, 37, 45, var(--tw-text-opacity))
}

.gutenberg .wp-block-button a,
.gutenberg li a,
.gutenberg p a,
.hover\:text-wtf-majorelle-blue:hover,
.text-wtf-majorelle-blue {
    --tw-text-opacity: 1;
    color: rgba(27, 153, 39)
}

.text-wtf-emerald,
.text-wtf-mango {
    --tw-text-opacity: 1;
    color: rgba(18, 77, 153)
}

.text-wtf-emerald {
    color: rgba(45, 202, 114, var(--tw-text-opacity))
}

.text-wtf-wild-blue-yonder {
    --tw-text-opacity: 1;
    color: rgba(137, 143, 169, var(--tw-text-opacity))
}

.hover\:text-wtf-cotton-candy:hover,
.text-wtf-cotton-candy {
    --tw-text-opacity: 1;
    color: rgba(252, 197, 243, var(--tw-text-opacity))
}

.antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

.opacity-0 {
    opacity: 0
}

.opacity-30 {
    opacity: .3
}

.opacity-40 {
    opacity: .4
}

.opacity-60 {
    opacity: .6
}

.group:hover .group-hover\:opacity-100,
.hover\:opacity-100:hover {
    opacity: 1
}

.shadow-sm {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-md {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)
}

.gutenberg .wp-block-image img,
.gutenberg .wp-block-video video,
.shadow-md,
.shadow-xl {
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-xl {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)
}

.transition {
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, -webkit-transform, -webkit-filter, -webkit-backdrop-filter;
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-transform, -webkit-filter, -webkit-backdrop-filter;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1)
}

.duration-150,
.transition {
    transition-duration: 150ms
}

.duration-500 {
    transition-duration: 500ms
}

.ease-in-out {
    transition-timing-function: cubic-bezier(.4, 0, .2, 1)
}

.scroll-smooth {
    scroll-behavior: smooth
}

.animation-delay-250ms {
    -webkit-animation-delay: .25s;
    animation-delay: .25s
}

.animation-delay-350ms {
    -webkit-animation-delay: .35s;
    animation-delay: .35s
}

.animation-delay-450ms {
    -webkit-animation-delay: .45s;
    animation-delay: .45s
}

.animation-delay-500ms {
    -webkit-animation-delay: .5s;
    animation-delay: .5s
}

.animate-fadein,
.animate-fadeindown,
.animate-fadeinup,
.animate-fadeoutup {
    -webkit-animation-name: fadein;
    animation-name: fadein;
    -webkit-animation-duration: .25s;
    animation-duration: .25s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out
}

.animate-fadeindown,
.animate-fadeinup,
.animate-fadeoutup {
    -webkit-animation-name: fadeinup;
    animation-name: fadeinup
}

.animate-fadeindown,
.animate-fadeoutup {
    -webkit-animation-name: fadeindown;
    animation-name: fadeindown
}

.animate-fadeoutup {
    -webkit-animation-name: fadeoutup;
    animation-name: fadeoutup
}

.animate-float-5 {
    -webkit-animation: float-5 3s .4s ease-in-out infinite;
    animation: float-5 3s .4s ease-in-out infinite
}

.animate-float-10 {
    -webkit-animation: float-10 4s .5s ease-in-out infinite;
    animation: float-10 4s .5s ease-in-out infinite
}

.animate-float-15 {
    -webkit-animation: float-15 5s .6s ease-in-out infinite;
    animation: float-15 5s .6s ease-in-out infinite
}

.flicker-fix {
    -webkit-transform-style: preserve-3d
}

@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(./wp-content/themes/ProfitSheets/fonts/inter/semibold.woff2) format('woff2'), url(./wp-content/themes/ProfitSheets/fonts/inter/semibold.woff) format('woff')
}

@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(./wp-content/themes/ProfitSheets/fonts/inter/medium.woff2) format("woff2"), url(./wp-content/themes/ProfitSheets/fonts/inter/medium.woff) format("woff")
}

@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(./wp-content/themes/ProfitSheets/fonts/inter/regular.woff2) format('woff2'), url(./wp-content/themes/ProfitSheets/fonts/inter/regular.woff) format('woff')
}

.font-inter {
    font-family: 'Inter', sans-serif
}

@font-face {
    font-family: 'Circular';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(./wp-content/themes/ProfitSheets/fonts/circular/bold.woff2) format('woff2'), url(./wp-content/themes/ProfitSheets/fonts/circular/bold.woff) format('woff')
}

@font-face {
    font-family: 'Circular';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(./wp-content/themes/ProfitSheets/fonts/circular/medium.woff2) format('woff2'), url(./wp-content/themes/ProfitSheets/fonts/circular/medium.woff) format('woff')
}

@font-face {
    font-family: 'Circular';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(./wp-content/themes/ProfitSheets/fonts/circular/book.woff2) format('woff2'), url(./wp-content/themes/ProfitSheets/fonts/circular/book.woff) format('woff')
}

.gutenberg h1 {
    font-family: 'Circular', sans-serif
}

@media (min-width:640px) {
    .gutenberg h1 {
        font-size: 53px;
        line-height: 72px;
        letter-spacing: -.025em
    }
}

.font-circular,
.gutenberg h2,
.gutenberg h3 {
    font-family: 'Circular', sans-serif
}

.gutenberg h2 {
    margin-top: 56px;
    font-size: 36px;
    line-height: 43px;
    letter-spacing: -.025em
}

@media (min-width:640px) {
    .gutenberg h2 {
        font-size: 48px;
        line-height: 56px;
        letter-spacing: -.025em
    }
}

.gutenberg h3 {
    font-size: 22px;
    line-height: 32px;
    letter-spacing: -.01em;
    font-weight: 500
}

@media (min-width:640px) {
    .gutenberg h3 {
        font-size: 24px;
        line-height: 32px;
        letter-spacing: -.01em
    }
}

.gutenberg ol,
.gutenberg p,
.gutenberg ul {
    margin-top: 16px;
    font-size: 16px;
    line-height: 28px
}

@media (min-width:640px) {
    .gutenberg p {
        margin-top: 24px;
        font-size: 18px;
        line-height: 32px
    }
}

.gutenberg li code,
.gutenberg p code {
    border-radius: 4px;
    border-width: 1px;
    --tw-border-opacity: 1;
    border-color: rgba(234, 234, 235, var(--tw-border-opacity));
    padding: 4px 8px
}

.gutenberg code {
    margin-top: 16px;
    max-width: 100%;
    border-radius: 8px
}

@media (min-width:640px) {
    .gutenberg code {
        font-size: 14px;
        line-height: 24px
    }
}

@media (min-width:768px) {
    .gutenberg code {
        margin-top: 24px
    }
}

.gutenberg ol,
.gutenberg ul {
    margin-left: 24px;
    list-style-type: disc
}

@media (min-width:640px) {
    .gutenberg ul {
        margin-top: 24px;
        font-size: 18px;
        line-height: 32px
    }
}

.gutenberg ol {
    list-style-type: decimal
}

@media (min-width:640px) {
    .gutenberg ol {
        margin-top: 24px;
        font-size: 18px;
        line-height: 32px
    }
}

.gutenberg .wp-block-button a,
.gutenberg li a,
.gutenberg p a {
    font-weight: 500
}

.gutenberg .wp-block-button a {
    display: -webkit-inline-flex;
    display: inline-flex;
    height: 40px;
    -webkit-align-items: center;
    align-items: center;
    white-space: nowrap;
    border-radius: 9999px;
    --tw-bg-opacity: 1;
    background-color: rgba(112, 71, 235, var(--tw-bg-opacity));
    padding-left: 24px;
    padding-right: 24px;
    font-size: 14px;
    line-height: 24px;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-transform, -webkit-filter, -webkit-backdrop-filter;
    transition-duration: 150ms;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1)
}

@media (min-width:640px) {
    .gutenberg .wp-block-button a {
        height: 56px;
        padding-left: 48px;
        padding-right: 48px;
        font-size: 16px;
        line-height: 28px
    }
}

.gutenberg .wp-block-image,
.gutenberg .wp-block-video {
    margin-top: 40px;
    margin-bottom: 40px
}

@media (min-width:768px) {

    .gutenberg .wp-block-image,
    .gutenberg .wp-block-video {
        margin-top: 56px;
        margin-bottom: 56px;
        margin-left: -10%;
        width: 120%
    }
}

.gutenberg .wp-block-image img,
.gutenberg .wp-block-video video {
    width: 100%;
    border-radius: 8px;
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)
}

@media (min-width:768px) {

    .gutenberg .wp-block-image img,
    .gutenberg .wp-block-video video {
        border-radius: 16px;
        --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
    }
}

.gutenberg .wp-block-embed-twitter {
    margin-top: 40px;
    margin-bottom: 40px;
    border-radius: 8px;
    --tw-bg-opacity: 1;
    background-color: rgba(247, 247, 248, var(--tw-bg-opacity));
    padding-top: 10rem;
    padding-bottom: 10rem
}

@media (min-width:768px) {
    .gutenberg .wp-block-embed-twitter {
        margin-top: 56px;
        margin-bottom: 56px;
        margin-left: -10%;
        width: 120%;
        border-radius: 16px;
        padding-top: 56px;
        padding-bottom: 56px
    }
}

.gutenberg .wp-block-embed-twitter .twitter-tweet {
    margin-left: auto;
    margin-right: auto
}

.gutenberg .wp-block-columns {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    padding-left: 32px
}

@media (min-width:768px) {
    .gutenberg .wp-block-columns {
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 56px;
        padding-left: 56px
    }
}

.gutenberg .wp-block-columns .wp-block-separator {
    margin-top: 16px
}

@media (min-width:640px) {
    .gutenberg .wp-block-columns .wp-block-separator {
        margin-top: 24px
    }
}

.gutenberg .wp-block-columns:last-of-type {
    padding-bottom: 32px
}

@media (min-width:768px) {
    .gutenberg .wp-block-columns:last-of-type {
        padding-bottom: 56px
    }
}

.gutenberg .wp-block-columns:not(:first-of-type) {
    margin-top: 32px;
    border-top-width: 1px;
    --tw-border-opacity: 1;
    border-color: rgba(234, 234, 235, var(--tw-border-opacity))
}

@media (min-width:768px) {
    .gutenberg .wp-block-columns:not(:first-of-type) {
        margin-top: 56px
    }
}

.gutenberg .wp-block-columns h1,
.gutenberg .wp-block-columns h2 {
    margin-top: 0
}

.gutenberg .wp-block-column:first-of-type {
    margin-top: 32px
}

@media (min-width:768px) {
    .gutenberg .wp-block-column:first-of-type {
        margin-top: 56px
    }
}

@media (min-width:768px) {
    .gutenberg.docs h3 {
        margin-top: 56px
    }
}

.gutenberg.docs h4 {
    margin-top: 24px;
    margin-bottom: -24px;
    border-top-width: 1px;
    --tw-border-opacity: 1;
    border-color: rgba(234, 234, 235, var(--tw-border-opacity));
    padding-top: 24px;
    font-weight: 600;
    font-family: 'Inter', sans-serif
}

.pricing-toggle-paddle {
    width: calc(50% - 2px);
    border-radius: 6px;
    box-shadow: 0 1px 1px rgba(10, 10, 46, .06);
    transition: .15s ease-in-out
}

.swiper-slide:not(.swiper-slide-active) .swiper-slide-inner {
    padding: 32px 40px;
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, .1), 0 10px 10px -5px rgba(0, 0, 0, .04)
}

.swiper-slide:not(.swiper-slide-active) {
    background-color: #fff;
    padding: 16px;
    opacity: 0;
    transition: .15s ease-in-out
}

@media screen and (min-width:768px) {
    .swiper-slide:not(.swiper-slide-active) {
        background-color: transparent;
        opacity: 1;
        transition: 0s
    }
}

.swiper-slide-active .swiper-slide-inner {
    padding: 32px 40px
}

@media screen and (min-width:768px) {
    .swiper-slide-active .swiper-slide-inner {
        padding: 32px 24px
    }
}

.swiper-slide-active {
    background-color: #fff;
    transition: .5s ease-in-out;
    padding: 16px 8px;
    margin: 0 8px;
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, .1), 0 10px 10px -5px rgba(0, 0, 0, .04)
}

@media screen and (min-width:768px) {
    .swiper-slide-active {
        padding: 16px;
        margin: 0 16px
    }
}

.swiper-container {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow-x: hidden;
    list-style: none;
    padding: 0;
    z-index: 30
}

.swiper-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: -webkit-flex;
    display: flex;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    box-sizing: content-box
}

.swiper-container-android .swiper-slide,
.swiper-wrapper {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
}

.swiper-container-free-mode>.swiper-wrapper {
    transition-timing-function: ease-out;
    margin: 0 auto
}

.swiper-container-pointer-events {
    touch-action: pan-y
}

.swiper-container-pointer-events.swiper-container-vertical {
    touch-action: pan-x
}

.swiper-slide {
    -webkit-flex-shrink: 0;
    flex-shrink: 0;
    width: auto;
    position: relative;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    transition: .15s ease-in-out
}

.swiper-slide-invisible-blank {
    visibility: hidden
}

.swiper-button-lock {
    display: none
}

pre code.hljs {
    display: block;
    overflow-x: auto;
    padding: 1em
}

code.hljs {
    padding: 3px 5px
}

.hljs {
    background: #501f7a;
    background: linear-gradient(166deg, #501f7a 0, #2820b3 80%)
}

.hljs,
.hljs-subtr {
    color: #e7e4eb
}

.hljs-comment,
.hljs-doctag,
.hljs-meta,
.hljs-quote {
    color: #af8dd9
}

.hljs-attr,
.hljs-regexp,
.hljs-selector-id,
.hljs-selector-tag,
.hljs-tag,
.hljs-template-tag {
    color: #aefbff
}

.hljs-bullet,
.hljs-params,
.hljs-selector-class {
    color: #f19fff
}

.hljs-keyword,
.hljs-meta .hljs-keyword,
.hljs-section,
.hljs-symbol,
.hljs-type {
    color: #17fc95
}

.hljs-link,
.hljs-number {
    color: #c5fe00
}

.hljs-string {
    color: #38c0ff
}

.hljs-addition,
.hljs-attribute {
    color: #e7ff9f
}

.hljs-template-variable,
.hljs-variable {
    color: #e447ff
}

.hljs-built_in,
.hljs-class,
.hljs-formula,
.hljs-function,
.hljs-name,
.hljs-title {
    color: #ffc800
}

.hljs-deletion,
.hljs-literal,
.hljs-selector-pseudo {
    color: #ff9e44
}

.hljs-emphasis,
.hljs-quote {
    font-style: italic
}

.hljs-keyword,
.hljs-params,
.hljs-section,
.hljs-selector-class,
.hljs-selector-id,
.hljs-selector-tag,
.hljs-strong,
.hljs-template-tag {
    font-weight: 700
}

@media (min-width:480px) {
    .xs\:-left-10pc {
        left: -10%
    }

    .xs\:-mt-30pc {
        margin-top: -30%
    }

    .xs\:pr-20pc {
        padding-right: 20%
    }
}

@media (min-width:640px) {
    .sm\:absolute {
        position: absolute
    }

    .sm\:top-0 {
        top: 0
    }

    .sm\:top-30pc {
        top: 30%
    }

    .sm\:right-0 {
        right: 0
    }

    .sm\:bottom-30pc {
        bottom: 30%
    }

    .sm\:-bottom-40pc {
        bottom: -40%
    }

    .sm\:-bottom-100pc {
        bottom: -100%
    }

    .sm\:left-40pc {
        left: 40%
    }

    .sm\:left-50pc {
        left: 50%
    }

    .sm\:left-120pc {
        left: 120%
    }

    .sm\:mt-0 {
        margin-top: 0
    }

    .sm\:mt-16px {
        margin-top: 16px
    }

    .sm\:mt-48px {
        margin-top: 48px
    }

    .sm\:mt-64px {
        margin-top: 64px
    }

    .sm\:mt-104px {
        margin-top: 104px
    }

    .sm\:block {
        display: block
    }

    .sm\:hidden {
        display: none
    }

    .sm\:h-56px {
        height: 56px
    }

    .sm\:h-full {
        height: 100%
    }

    .sm\:max-h-full {
        max-height: 100%
    }

    .sm\:w-auto {
        width: auto
    }

    .sm\:max-w-screen-xl {
        max-width: 1280px
    }

    .sm\:max-w-740px {
        max-width: 740px
    }

    .sm\:grid-cols-3 {
        grid-template-columns: repeat(3, minmax(0, 1fr))
    }

    .sm\:gap-0 {
        gap: 0
    }

    .sm\:rounded-none {
        border-radius: 0
    }

    .sm\:rounded-8px {
        border-radius: 8px
    }

    .sm\:rounded-r-none {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0
    }

    .sm\:rounded-l-none {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0
    }

    .sm\:px-16px {
        padding-left: 16px;
        padding-right: 16px
    }

    .sm\:px-40px {
        padding-left: 40px;
        padding-right: 40px
    }

    .sm\:px-48px {
        padding-left: 48px;
        padding-right: 48px
    }

    .sm\:py-80px {
        padding-top: 80px;
        padding-bottom: 80px
    }

    .sm\:py-92px {
        padding-top: 92px;
        padding-bottom: 92px
    }

    .sm\:py-112px {
        padding-top: 112px;
        padding-bottom: 112px
    }

    .sm\:py-120px {
        padding-top: 120px;
        padding-bottom: 120px
    }

    .sm\:py-176px {
        padding-top: 176px;
        padding-bottom: 176px
    }

    .sm\:pt-32px {
        padding-top: 32px
    }

    .sm\:pt-112px {
        padding-top: 112px
    }

    .sm\:pt-120px {
        padding-top: 120px
    }

    .sm\:pt-212px {
        padding-top: 212px
    }

    .sm\:pr-16px {
        padding-right: 16px
    }

    .sm\:pr-32px {
        padding-right: 32px
    }

    .sm\:pr-40pc {
        padding-right: 40%
    }

    .sm\:pl-16px {
        padding-left: 16px
    }

    .sm\:pl-32px {
        padding-left: 32px
    }

    .sm\:text-16px {
        font-size: 16px;
        line-height: 28px
    }

    .sm\:text-18px {
        font-size: 18px;
        line-height: 32px
    }

    .sm\:text-30px {
        font-size: 30px;
        line-height: 40px;
        letter-spacing: -.025em
    }

    .sm\:text-48px,
    .sm\:text-64px {
        font-size: 48px;
        line-height: 56px;
        letter-spacing: -.025em
    }

    .sm\:text-64px {
        font-size: 64px;
        line-height: 72px
    }

    .sm\:font-400 {
        font-weight: 400
    }

    .sm\:leading-24px {
        line-height: 24px
    }

    .sm\:shadow-none,
    .sm\:shadow-xl {
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
    }

    .sm\:shadow-xl {
        --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)
    }

    .sm\:shadow-none {
        --tw-shadow: 0 0 #0000
    }
}

@media (min-width:768px) {
    .md\:visible {
        visibility: visible
    }

    .md\:invisible {
        visibility: hidden
    }

    .md\:absolute {
        position: absolute
    }

    .md\:relative {
        position: relative
    }

    .md\:top-auto {
        top: auto
    }

    .md\:top-56px {
        top: 56px
    }

    .md\:top-15pc {
        top: 15%
    }

    .md\:top-30pc {
        top: 30%
    }

    .md\:top-35pc {
        top: 35%
    }

    .md\:top-45pc {
        top: 45%
    }

    .md\:-top-132px {
        top: -132px
    }

    .md\:-top-256px {
        top: -256px
    }

    .md\:-top-10pc {
        top: -10%
    }

    .md\:top-1\/2 {
        top: 50%
    }

    .md\:right-0 {
        right: 0
    }

    .md\:right-60px {
        right: 60px
    }

    .md\:right-96px {
        right: 96px
    }

    .md\:right-10pc {
        right: 10%
    }

    .md\:right-25pc {
        right: 25%
    }

    .md\:right-50pc {
        right: 50%
    }

    .md\:right-80pc {
        right: 80%
    }

    .md\:-right-180px {
        right: -180px
    }

    .md\:-right-10pc {
        right: -10%
    }

    .md\:bottom-0 {
        bottom: 0
    }

    .md\:bottom-10pc {
        bottom: 10%
    }

    .md\:bottom-15pc {
        bottom: 15%
    }

    .md\:bottom-25pc {
        bottom: 25%
    }

    .md\:bottom-50pc {
        bottom: 50%
    }

    .md\:-bottom-60px {
        bottom: -60px
    }

    .md\:-bottom-10pc {
        bottom: -10%
    }

    .md\:-bottom-20pc {
        bottom: -20%
    }

    .md\:-bottom-30pc {
        bottom: -30%
    }

    .md\:left-0 {
        left: 0
    }

    .md\:left-60px {
        left: 60px
    }

    .md\:left-96px {
        left: 96px
    }

    .md\:left-20pc {
        left: 20%
    }

    .md\:left-25pc {
        left: 25%
    }

    .md\:left-55pc {
        left: 55%
    }

    .md\:left-60pc {
        left: 60%
    }

    .md\:left-80pc {
        left: 80%
    }

    .md\:left-100pc {
        left: 100%
    }

    .md\:-left-10pc {
        left: -10%
    }

    .md\:-left-480px {
        left: -480px
    }

    .md\:mx-0 {
        margin-left: 0;
        margin-right: 0
    }

    .md\:mt-0 {
        margin-top: 0
    }

    .md\:mt-20px {
        margin-top: 20px
    }

    .md\:mt-48px {
        margin-top: 48px
    }

    .md\:mt-64px {
        margin-top: 64px
    }

    .md\:mt-72px {
        margin-top: 72px
    }

    .md\:mt-196px {
        margin-top: 196px
    }

    .md\:mt-276px {
        margin-top: 276px
    }

    .md\:mt-296px {
        margin-top: 296px
    }

    .md\:mr-8 {
        margin-right: 2rem
    }

    .md\:ml-auto {
        margin-left: auto
    }

    .md\:ml-5pc {
        margin-left: 5%
    }

    .md\:-ml-5pc {
        margin-left: -5%
    }

    .md\:block {
        display: block
    }

    .md\:inline-block {
        display: inline-block
    }

    .md\:flex {
        display: -webkit-flex;
        display: flex
    }

    .md\:grid {
        display: grid
    }

    .md\:hidden {
        display: none
    }

    .md\:h-40px {
        height: 40px
    }

    .md\:w-auto {
        width: auto
    }

    .md\:w-20pc {
        width: 20%
    }

    .md\:w-25pc {
        width: 25%
    }

    .md\:w-30pc {
        width: 30%
    }

    .md\:w-40pc {
        width: 40%
    }

    .md\:w-50pc {
        width: 50%
    }

    .md\:w-60pc {
        width: 60%
    }

    .md\:w-90pc {
        width: 90%
    }

    .md\:w-120pc {
        width: 120%
    }

    .md\:w-full {
        width: 100%
    }

    .md\:max-w-2xl {
        max-width: 42rem
    }

    .md\:max-w-6xl {
        max-width: 72rem
    }

    .md\:max-w-full {
        max-width: 100%
    }

    .md\:max-w-screen-2xl {
        max-width: 1536px
    }

    .md\:max-w-740px {
        max-width: 740px
    }

    .md\:scale-100 {
        --tw-scale-x: 1;
        --tw-scale-y: 1
    }

    .md\:grid-flow-row {
        grid-auto-flow: row
    }

    .md\:grid-cols-2 {
        grid-template-columns: repeat(2, minmax(0, 1fr))
    }

    .md\:flex-row {
        -webkit-flex-direction: row;
        flex-direction: row
    }

    .md\:items-end {
        -webkit-align-items: flex-end;
        align-items: flex-end
    }

    .md\:items-center {
        -webkit-align-items: center;
        align-items: center
    }

    .md\:justify-start {
        -webkit-justify-content: flex-start;
        justify-content: flex-start
    }

    .md\:gap-32px {
        gap: 32px
    }

    .md\:gap-48px {
        gap: 48px
    }

    .md\:gap-96px {
        gap: 96px
    }

    .md\:rounded-none {
        border-radius: 0
    }

    .md\:rounded-8px {
        border-radius: 8px
    }

    .md\:rounded-16px {
        border-radius: 16px
    }

    .md\:px-16 {
        padding-left: 4rem;
        padding-right: 4rem
    }

    .md\:px-32px {
        padding-left: 32px;
        padding-right: 32px
    }

    .md\:py-44px {
        padding-top: 44px;
        padding-bottom: 44px
    }

    .md\:pt-56px {
        padding-top: 56px
    }

    .md\:pt-104px {
        padding-top: 104px
    }

    .md\:pt-120px {
        padding-top: 120px
    }

    .md\:pt-224px {
        padding-top: 224px
    }

    .md\:pr-32px {
        padding-right: 32px
    }

    .md\:pr-56px {
        padding-right: 56px
    }

    .md\:pr-100px {
        padding-right: 100px
    }

    .md\:pb-176px {
        padding-bottom: 176px
    }

    .md\:pb-196px {
        padding-bottom: 196px
    }

    .md\:pb-220px {
        padding-bottom: 220px
    }

    .md\:pl-32px {
        padding-left: 32px
    }

    .md\:text-left {
        text-align: left
    }

    .md\:text-right {
        text-align: right
    }

    .md\:text-14px {
        font-size: 14px;
        line-height: 24px
    }

    .md\:text-16px {
        font-size: 16px;
        line-height: 28px
    }

    .md\:opacity-0 {
        opacity: 0
    }

    .md\:shadow-2xl,
    .md\:shadow-none {
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
    }

    .md\:shadow-2xl {
        --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25)
    }

    .md\:shadow-none {
        --tw-shadow: 0 0 #0000
    }
}

@media (min-width:1024px) {
    .lg\:visible {
        visibility: visible
    }

    .lg\:relative {
        position: relative
    }

    .lg\:right-0 {
        right: 0
    }

    .lg\:right-96px {
        right: 96px
    }

    .lg\:right-156px {
        right: 156px
    }

    .lg\:-bottom-160px {
        bottom: -160px
    }

    .lg\:left-auto {
        left: auto
    }

    .lg\:left-50pc {
        left: 50%
    }

    .lg\:left-120pc {
        left: 120%
    }

    .lg\:-left-10pc {
        left: -10%
    }

    .lg\:mt-0 {
        margin-top: 0
    }

    .lg\:mt-304px {
        margin-top: 304px
    }

    .lg\:mr-32px {
        margin-right: 32px
    }

    .lg\:inline-block {
        display: inline-block
    }

    .lg\:flex {
        display: -webkit-flex;
        display: flex
    }

    .lg\:hidden {
        display: none
    }

    .lg\:max-w-screen-xl {
        max-width: 1280px
    }

    .lg\:max-w-900px {
        max-width: 900px
    }

    .lg\:grid-cols-2 {
        grid-template-columns: repeat(2, minmax(0, 1fr))
    }

    .lg\:items-center {
        -webkit-align-items: center;
        align-items: center
    }

    .lg\:py-212px {
        padding-bottom: 212px
    }

    .lg\:pt-212px,
    .lg\:py-212px {
        padding-top: 212px
    }

    .lg\:pr-0 {
        padding-right: 0
    }

    .lg\:pr-24 {
        padding-right: 6rem
    }
}

@media (min-width:1280px) {
    .xl\:px-48px {
        padding-left: 48px;
        padding-right: 48px
    }
}

.bg-wtf-majorelle-green {
    background-color: rgb(2 56 95)
}

.bg-wtf-blue {
    background-color: rgb(26 153 38);
}

.hover:bg-wtf-blue-hover {
    background-color: rgb(3 184 25);
}

.text-success {
    color: green;
}

.text-warning {
    color: #d1d11a;
}

.text-danger {
    color: red;
}

.table,
table td,
table th {
    border: 1px solid #f1f1f1
}

table thead {
    background: #e9ffeb;
}

table td,
.table-sm th,
.table-sm td {
    font-size: 15px;
    padding: 0px;
}

table th {
    font-size: 14px;
}

.form-input {
    --tw-bg-opacity: 1;
    --tw-text-opacity: 1;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
    border-radius: 8px;
    border-width: 0;
    color: rgba(37, 37, 45, var(--tw-text-opacity));
    font-size: 14px;
    line-height: 24px;
    padding: 8px 16px;
    width: 100%;
}

.form-input {
    box-shadow: inset 0 0 0 1px rgb(10 10 46 / 16%), 0 1px 1px rgb(10 10 11 / 6%);
}

.packages-wrap .bg-white {
    border: 1px solid #d1d1d1;
}

.bg-wtf-bluee {
    background-color: rgb(53 56 157);
}

.title {
    font-size: 15px;
    position: relative;
    bottom: 33px;
    border-bottom: 1px solid #f1f1f1;
}

.text-dark {
    color: #000 !important;
}

.text-grey {
    color: #888 !important;
}

.control-label {
    font-size: 14px;
}

.form-group {
    margin-bottom: 15px;
}

.text-right {
    text-align: right !important;
}

.text-left {
    text-align: left !important;
}

.float-right {
    float: right !important;
}

.float-left {
    float: left !important;
}

.clearfix {
    clear: both;
    display: table;
    width: 100%;
}

.font-14 {
    font-size: 14px;
}

.font-15 {
    font-size: 15px;
}

.yBoxFrame {
    background: rgba(255, 255, 255) !important;
}

.closeYbox {
    background: #fff !important;
}

.closeYbox::after,
.closeYbox::before {
    background-color: #000 !important;
}

.yBoxOverlay.active .yBoxFrame {
    border-radius: 5px;
}

.insertYboxAjaxHere {
    overflow: auto;
    overflow-x: clip;
}

.insertYboxAjaxHere::-webkit-scrollbar {
    width: 6px;
    background-color: #F5F5F5;
}

.insertYboxAjaxHere {
    max-height: 100% !important;
}

.insertYboxAjaxHere::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: #123170;
}

.tabs ul {
    background: #ddd;
    display: flex;
    border-radius: 5px;
}

.tabs ul li {
    float: left;
    min-width: 50%;
    color: #000;
    text-align: center;
    font-size: 14px;
    border-radius: 5px;
    cursor: pointer;
}

.tabs ul li.active {
    background: #1a9926;
    color: #fff;
}

.btn-size {
    padding: 0px 25px;
    height: 40px;
    display: block;
    width: 100%;
}

.col25 {
    width: 25%;
}

.col33 {
    width: 33.33%;
    padding-top: 62px;
}

.subscription .details {
    text-align: right;
}

.subscription .details h3,
.subscription .free h3 {
    margin-bottom: 1.5rem;
}

.subscription .details ul li,
.subscription .free ul li,
.subscription .one ul li {
    padding: 0.5rem 2rem;
    list-style: none;
}

.gutenberg ul {
    margin-left: 0;
}

.subscription .details ul li {
    border-bottom: solid 1px transparent;
}

.subscription .free {
    background-color: #FFF;
    padding: 3rem 0;
    border-radius: 1rem 0 0 1rem;
    box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.subscription .free ul li:not(:last-child) {
    border-bottom: solid 1px #F4F4F4;
}

.subscription .one {
    background-color: #02385f;
    padding: 5rem 0;
    border-radius: 1rem;
    color: #fff;
    text-align: center;
    margin-top: 0px;
}

.flex-align-center {
    align-items: center;
}

.subscription .one ul li:not(:last-child) {
    border-bottom: solid 1px #0a4a78;
}

.bg-secondary {
    background-color: #01b718;
    color: #fff;
    padding: 10px 25px;
    margin-top: 17px;
    display: inline-block;
    font-weight: 600;
    border-radius: 8px;
}

.col100 {
    width: 100%;
}

.flex-align-center {
    align-items: center;
}

.flex-container {
    display: flex;
}

.flex-wrap {
    flex-wrap: wrap;
}

.bg-primary {
    background-color: #02385f;
    color: #fff;
    padding: 10px 25px;
    margin-top: 17px;
    display: inline-block;
    font-weight: 600;
    border-radius: 8px;
}

.marquee-wrapper {
    position: relative;
    width: 100vw;
    max-width: 100%;
    height: 200px;
    overflow-x: hidden;

}

.scroll {
    position: absolute;
    white-space: nowrap;
    will-change: transform;
    animation: marquee 0.9s linear infinite;
}

.marquee-wrapper:hover {
    -webkit-animation-play-state: paused;
    -moz-animation-play-state: paused;
    -o-animation-play-state: paused;
    animation-play-state: paused;
}

.red_bse {
    color: #f64747;
}

.wh_bse {
    color: #000;
}

.rdtxt {
    color: #c00;
}



@keyframes marquee {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(-50%);
    }
}

#site-header {
    background: #f1f1f1a6;
    z-index: -1;
}

.box {
    /*box-shadow: 0px 0px 14px -3px rgb(0 0 0 / 50%);*/
    border-radius: 8px;
    padding: 10px;
    min-height: 335px;
    overflow: auto;
}

.gutenberg li {
    list-style: none;
}

.nav-pills .nav-link {
    font-size: 12px;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    background-color: #000000;
    padding: 0 14px;
    margin: 8px 0;
}

.gn_bse {
    color: #00ba00;
}

.box-wrap {
    /* box-shadow: 0px 0px 14px -3px rgb(0 0 0 / 50%); */
    border-radius: 8px;
    padding: 5px;
    background: #fff;
    transition: all .3s ease-in-out;
}

.box-wrap img{
    border-radius: 8px;
    height:156px;
    width:100%;
}
    .box-wrap .desc {
        line-height: 17px;
        display: block;
        padding-top: 6px;
        padding-bottom:8px;
        font-size: 12px;
    }
.box-wrap:hover{
    background:#f0f3fa;
    border-radius: 8px;
}


.font-title {
    font-size: 16px !important;
    line-height: 20px !important;
    font-weight: 500;
}

.form-control {
    -webkit-appearance: auto;
    -moz-appearance: auto;
    appearance: auto;
}

#pills-tabContent .table,
table td,
table th,
.borderless {
    border: none;
}

.font-bold {
    font-weight: bold;
}

.font-normal {
    font-weight: normal;
}

#TOP_GN td a {
    font-weight: bold
}

#pills-tabContent table thead th {
    font-size: 12px;
}

.small-btn {
    line-height: 22px;
    border: 1px solid #9f9f9fa6;
}

.txt14_bb {
    margin-top: 8px;
    color: #3c3c3c;
    font-size: 13px;
    font-weight: 800;
}

.ML15 {
    margin-left: 15px;
}

.green_arw {
    display: inline-block;
    border-bottom: 6px solid #070;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
}

.grntxt {
    color: #070;
}

.fn_smalltxt {
    font-size: 12px;
    font-weight: 400;
}

.MR10 {
    margin-right: 10px;
}

.red-arw {
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid #c00;
    display: inline-block;
    vertical-align: middle;
    width: 1px;
    height: 4px;
}

#advance_decline_unchange {
    display: flex;
    font-size: 12px;
}

.marctcNv {
    width: 30px;
    margin-bottom: 30px;
    margin-top: 0 !important;
    float: left;
    background: #fff;
    border-left: 1px solid #f5f5f5;
}

.marctcNv li {
    border-bottom: 1px solid #f5f5f5;
}

.marctcNv li a {
    font: 700 12px/16px 'Lato', sans-serif;
    color: #3c3c3c;
    display: block;
    text-align: center;
}

.marctcNv li.active a,
.marctcNv li:hover {
    background: #c8c8c8;
}

.ifr {
    float: left;
}

.table th,
.table td {
    position:relative;
    /*border-top: 1px solid transparent;*/
}



.subscription-msg {
    top: 45%;
    /* left: 30%; */
    background: rgba(255, 255, 255, 0.6);
    padding: 20px;
    /* box-shadow: 0 0 26px 5px rgb(0 0 0 / 24%); */
    border-radius: 50px;
}

.small-text {
    font-size: 12px;
    float: right;
}

.buy {
    border: 1px solid #47c191;
    color: #47c191;
}

.sell {
    border: 1px solid #fc7653;
    color: #fc7653;
}

.buy,
.sell {
    min-width: 60px;
    padding: 0 10px;
    text-transform: uppercase;
    display: inline-block;
    text-align: center;
    box-sizing: border-box;
}

.text-cut {
    font-size: 13px;
}

/* popup styles  */
.overlay {
    display: none;
    position: fixed;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    background-color: #444;
    z-index:2001;
    -moz-opacity: 0.7;
    opacity:.80;
    filter: alpha(opacity=80);
    -webkit-transition: opacity 1s ease-in-out;
    -moz-transition: opacity 1s ease-in-out;
    -ms-transition: opacity 1s ease-in-out;
    -o-transition: opacity 1s ease-in-out;
}

.popup {
    box-shadow: 5px 5px 5px #444;
    position: absolute;
    background-color: rgb(255, 255, 255);
    display: none;
    position: absolute;
    top: 15%;
    left: 31%;
    width: 40%;
    padding: 10px 10px 20px;
    border: 10px solid white;
    z-index: 20010;
    overflow: hidden;
    border-radius: 10px;
    -webkit-transition: all 1s ease-in-out;
    -moz-transition: all 1s ease-in-out;
    -o-transition: all 1s ease-in-out;
    -ms-transition: all 1s ease-in-out;
    transition: all 1s ease-in-out;
    opacity: 1;
}
@media screen and (max-width:600px){
    .popup{
        width:80%;
        left:10%;
    }
    #mobile-menu {
        background: #033960;
    }
    #mobile-menu a {
        padding: 0 25px;
        min-width: fit-content;
        border-radius: 0 !important;
        background: none;
        color: #fff !important;
        background-color: none !important;
        border-bottom: 1px solid #1a5682;
    }
    .mobile-active {
        display: block;
        opacity: 1 !important;
        visibility: visible !important;
        overflow: auto;
    }
    #site-header .max-w-screen-xl{
        padding:10px 5px;
    }
    .table-responsive {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;
    }
    .xs-full {
        flex-direction: initial;
        margin-top: 55px;
    }
    #mobile-menu div {
        display: block;
        background: #033960;
        padding-left: 0;
        padding-right: 0;
    }
        #mobile-menu div a {
            background: none;
            color: #fff;
            text-align: left;
            justify-content: flex-start;
            box-shadow: none !important;
            margin-left: 0;
        }
            #mobile-menu div a:last-child {
                background: #FFA500;
                border-radius: 0;
                width: 100%;
            }
    .center-mobi {
        display: -webkit-inline-box !important;
        text-align: center;
        margin: 0 auto 10px;
    }
    .padd-0-mobi {
        padding:0;
    }
    .mobiscreen {
        padding: 15px;
    }
    .subscribe-box {
        box-shadow: none !important;
        border: none !important;
    }
        .subscribe-box img {
            width: auto;
            height: 100px;
            margin: 0 auto;
        }
    .gutenberg h3 {
        font-size: 26px;
        padding: 0 10px 10px;
        width: 100%;
        display: block !important;
    }
    .mob-wide {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}
#mobile-toggle {
    padding-left: 25px;
    padding-top: 8px;
}
.popup-text {
    margin-top: 6%;
    color: white;
    text-align: center;
}
.popup-close {
    text-align: right;
	  position: absolute;
    top: 10px;
    right: 10px;
    font-size: 1.0em;
}

/* popup styles ends */
.subscribe-box {
    box-shadow: 5px 3px 8px -3px rgb(0 0 0 / 40%);
    border-radius: 10px;
    /* padding: 30px;*/
    margin-bottom: 20px;
    border: 1px solid #d2d2d2;
    /*background: #e3606121;*/
}
.card-exterior-RYyl75pN {
    border-radius: 16px;
    box-sizing: border-box;
    padding: 8px;
}
.article-gaCYEutU {
    color: #131722;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}
.container-gaCYEutU {
    display: flex;
    flex-direction: column;
    width: 100%;
}
.header-gaCYEutU {
    align-items: center;
    color: #6a6d78;
    display: flex;
    height: 18px;
    margin-bottom: 8px;
}
.breadcrumbs-gaCYEutU {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
}
.title-gaCYEutU {
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    padding-top: 10px;
}
.name{
    padding-left: 25px;
}
.name::before{
    content: ".";
    font-size: 29px;
    position: relative;
    vertical-align: super;
    top: 4px;
    right: 7px;
}
.card-gaCYEutU {
    display: block;
    border-radius: 8px;
    min-height: 130px;
    transition: all .2s ease-in-out;
}
    .card-gaCYEutU:hover {
        background-color: #f0f3fa;
        cursor: pointer;
        display: block;
        
    }
.btn-orange {
    background:#FFA500;
}
    .btn-orange:hover {
        background: rgb(2 56 95);
    }
.blurr {
    -webkit-filter: blur(2px);
    -moz-filter: blur(2px);
    -o-filter: blur(2px);
    -ms-filter: blur(2px);
    filter: blur(2px);
}
.text-overlap {
    top: 4px;
    left: 0;
    width: 100%;
    background: rgba(255,255,255,0.5);
    padding: 40px 0;
    border-radius: 10px;
    margin: 0 auto;
    text-align: center;
}

/*News details popup*/
.overlayy {
    position: absolute;
    top: 0px;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100% !important;
    background: rgba(255,255,255);
    transition: opacity 50ms;
    visibility: hidden;
    /*opacity: 0;*/
    display: flex;
    z-index: 1;
    justify-content: center;
    align-items: center;
}

    .overlayy:target {
     /*   visibility: visible;*/
       /* opacity: 1;*/
    }

.popupp {
    padding: 20px;
    background: #ffffff;
    border-radius: 5px;
    width: 100%;
    height: 100% !important;
    max-height: 100%;
    position: relative;
    transition: all 1s ease-in-out;
    color: #000000;
}

    .popupp h2 {
        margin-top: 0;
        color: white;
        font-family: Roboto, Arial, sans-serif;
    }

    .popupp .close {
        position: absolute;
        top: 0px;
        right: 9px;
        transition: all 200ms;
        font-size: 30px;
        font-weight: bold;
        text-decoration: none;
        color: #000000;
    }

        .popupp .close:hover {
            color: #000;
        }

    .popupp .content {
        padding:50px 0;
        overflow: auto;
    }
/*News details popup ends */

.bg-blue {
    background: rgb(2 56 95);
}
.fullstory-box{
    border-radius:8px;
}
.hover:text-wtf-majorelle-blue-hover:hover {
    color: rgb(37,196,55) !important;
}
#topgainers1 div:nth-child(2), #topgainers1 div:nth-child(3), #topgainers1 div:nth-child(4), #makeaction div:nth-child(2), #makeaction div:nth-child(3), #makeaction div:nth-child(4),
#tradenews div:nth-child(2), #tradenews div:nth-child(3), #tradenews div:nth-child(4) {
    display: none;
}
#topgainers1 div, #topgainers1 div iframe {
    width: 100% !important;
    min-height: 400px !important;
}
    #makeaction div, #makeaction div iframe {
        width: 100% !important;
        min-height: 200px !important;
    }
.aboutus-text p, .privacypolicy-text p, .contact-text p {
    font-size: 15px;
    margin-top: 7px;
}
#mobile-toggle {
    padding-left: 6px;
    padding-top: 8px;
}

.mobile-nabars {
    width: 99.5%;
    overflow-x: auto;
}
#mobile-menu {
    overflow: auto;
    margin: 10px 0;
}
.fb-icon {
    border: 1px solid;
    padding: 6px 10px;
    border-radius: 50%;
    color:#3b5998;
}
.wt-icon {
    border: 1px solid;
    padding: 6px 9px;
    border-radius: 50%;
    color: #25D366;
}
.tw-icon {
    border: 1px solid;
    padding: 6px 9px;
    border-radius: 50%;
    color: #00acee;
}
.link-icon {
    border: 1px solid;
    padding: 6px 9px;
    border-radius: 50%;
    color: #0A66C2
}